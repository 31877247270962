@font-face {
  font-family: 'Proxima Nova Rg';
  src: url('../../../assets/fonts/ProximaNova-Bold.woff2') format('woff2'),
    url('../../../assets/fonts/ProximaNova-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Rg';
  src: url('../../../assets/fonts/ProximaNova-Regular.woff2') format('woff2'),
    url('../../../assets/fonts/ProximaNova-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Lt';
  src: url('../../../assets/fonts/ProximaNova-Light.woff2') format('woff2'),
    url('../../../assets/fonts/ProximaNova-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Lt';
  src: url('../../../assets/fonts/ProximaNova-Semibold.woff2') format('woff2'),
    url('../../../assets/fonts/ProximaNova-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

html {
  font-size: 14px;
  // font-family: 'Proxima Nova Lt';
}
