@import 'themes';
@import '@nebular/theme/styles/globals';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/grid';

@import './datch.material.theme.scss';
@import './dragula.scss';

@import './font';

@include nb-install() {
  @include nb-theme-global();

  .alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
  }

  // fixed in rc.9 and can be removed after upgrade
  .custom-control .custom-control-indicator {
    border-radius: 50%; // TODO: quickfix for https://github.com/akveo/nebular/issues/275
  }
  .center-snackbar > .mat-simple-snackbar {
    justify-content: center;
  }

  snack-bar-container.error {
    background-color: rgb(212, 0, 0);
    > .mat-simple-snackbar {
      justify-content: center;
    }
  }

  .no-padding-dialog {
    mat-dialog-container {
      padding: 0;
    }
  }
}

// fix for bootstrap button border change
.btn {
  border-radius: 0.3rem !important;
}
